import { captureRemixErrorBoundaryError } from "@sentry/remix";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { rootAuthLoader } from "@clerk/remix/ssr.server";
import {
  ClerkApp,
  OrganizationSwitcher,
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
} from "@clerk/remix";
import styles from "./tailwind.css?url";

export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

export const loader = async (args: LoaderFunctionArgs) => {
  return rootAuthLoader(args, () => {
    return {
      ENV: {
        APP_ENV: process.env.APP_ENV,
      },
    };
  });
};
function App() {
  const data = useLoaderData<typeof loader>();

  return (
    <html lang="en" className="h-full bg-gray-50">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <div className="min-h-full">
          <nav className="bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0 sm:-ml-4">
                    <a href="/">
                      <img
                        className="h-8 w-8"
                        src="/logo-light.png"
                        alt="Logo"
                      />
                    </a>
                  </div>
                  <div className="ml-10 flex items-baseline space-x-4">
                    <OrganizationSwitcher  hidePersonal={true} afterSelectOrganizationUrl={() => "/refresh"} />
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="ml-4 flex items-center md:ml-6">
                    <SignedIn>
                      <UserButton />
                    </SignedIn>
                    <SignedOut>
                      <span className="text-gray-300 px-3 py-2 rounded-md text-sm font-medium">
                        <SignInButton />
                      </span>
                    </SignedOut>
                  </div>
                </div>
              </div>
            </div>
          </nav>

          <main>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <Outlet />
              <script
                dangerouslySetInnerHTML={{
                  __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
                }}
              />
            </div>
          </main>
        </div>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default ClerkApp(App, {});
